.form-group {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.text-error {
  color: red;
  display: block;
}

// For Hide/show password
.password-form-wrapper {
  position: relative;
}

.password-eye {
  position: absolute;
  top: 7px;
  right: 30px;
  font-size: 1.5em;
}

.bar-between-text {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1em;
  align-items: center;
  font-size: 1rem;
  color: $color-secondary;
  &::before,
  &::after {
    content: '';
    height: 1px;
    display: block;
    background-color: currentColor;
  }
}
