.signup-container {
    position: relative;
    width: 100%;
    height: 140vh;
    display: flex;
    @include respond(phone) {
        width: 100%;
        height: 100%;
    }
    .form-container {
        width: 50%;
        background-color: $color-primary-light;
        height: inherit;
        margin-bottom: -2em;
        @include respond(phone) {
            width: 100%;
        }
        .main-form {
            width: 80%;
            padding: .95em 2em;
            margin: auto;
            @include respond(phone) {
                width: 95%;
            }
            .input {
                background-color: inherit;
                border: 2px solid #E5E9F2;
                box-sizing: border-box;
                border-radius: 5px;
                line-height: 3;
                padding-left: .5em;
                width: 100%;
                margin-top: -.5em;
                &:focus {
                    outline-color: $color-primary;
                }
                &::placeholder {
                    color: $color-gray;
                }
            }
            .bar-between-text {
                color: $color-dark;
                &::before {
                    background-color: $color-dark;
                }
                &::after {
                    background-color: $color-dark;
                }
            }
            .social-media-icons {
                width: 100%;
                margin: auto;
                text-align: center;
                .icon {
                    margin-right: 2em;
                    margin-left: 2em;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .form-bg {
        position: relative;
        width: 50%;
        @include respond(phone) {
            display: none;
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}