// Header
.account {
    img {
        margin-right: 1em;
        margin-left: 1em;
    }
    @include respond(phone) {
        margin-top: .6em;
    }
}

// Side Nav
.side-nav {
    position: fixed;
    @include respond(tab-port) {
        position: relative !important;
    }
    .CircularProgressbar-path {
        stroke: $color-primary !important;
    }
    .CircularProgressbar .CircularProgressbar-text {
        fill: $color-black !important;
        font-weight: bold;
    }
    .CircularProgressbar .CircularProgressbar-trail {
        stroke: #A0B1C4;
    }
    .profile-progress {
        margin: 0 .7em;
        text-align: center;
        width: 100%;
    }
    nav {
        background-color: $color-white;
        border: none !important;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
        padding: 1em;
        border-radius: .5em;
        height: 25em;
        margin: 0 2em;
        width: 90%;
        ul {
            list-style-type: none;
            a {
                color: $color-black;
            }
            li.nav-item {
                margin-left: -2em;
                margin-bottom: 2em;
                &:hover:not(li.active) {
                    cursor: pointer;
                    color: $color-primary;
                }
                &::before {
                    content: '';
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    -moz-border-radius: 7.5px;
                    -webkit-border-radius: 7.5px;
                    border-radius: 7.5px;
                    border: 1px solid #A0B1C4;
                    margin-right: .5em;
                }
            }
            li.nav-dropdown:hover,
            li.active.nav-dropdown:hover {
                padding: .5em;
                background-color: $color-primary !important;
                color: $color-white !important;
                border-radius: .3em;
                &::before {
                    display: none;
                }
                .nav-dropdown-menu {
                    height: auto;
                }
            }
            li.active.nav-dropdown {
                background-color: $color-primary !important;
                padding: .5em;
                color: $color-white;
                border-radius: .3em;
                &::before {
                    display: none;
                }
                .nav-dropdown-menu {
                    height: auto;
                }
            }
            li.active:not(li.active.nav-dropdown) {
                color: $color-primary;
            }
            .nav-dropdown-menu {
                background-color: $color-primary !important;
                color: $color-white;
                height: 0;
                overflow: hidden;
                transition: height 2s;
            }
            li.nav-dropdown-item {
                font-size: .8em;
                margin-bottom: .7em;
                opacity: 0.6;
                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
                &:nth-of-type(1) {
                    margin-top: .7em;
                }
                &.sub-active {
                    opacity: 1;
                }
            }
            li.nav-dropdown {
                margin-top: 1.3em;
            }
        }
    }
}