// Device management
.device-management {
    .table {
        width: 100%;
        th {
            // text-align: center;
            background-color: $color-white !important;
            border: none !important;
            padding: 1.8em 1.7em 1.8em 1.7em;
        }
        td {
            padding: 1.7em 1.7em;
        }
    }
    .form-btn-action {
        margin-right: 2em;
        button {
            margin: .5em .5em;
        }
    }
}