.forgot-password-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    .form-container {
        width: 50%;
        background-color: $color-primary-light;
        @include respond(phone) {
            width: 100%;
        }
        .main-form {
            width: 80%;
            padding: 3em;
            margin: auto;
            @include respond(phone) {
                width: 95%;
            }
            .input {
                background-color: inherit;
                border: 2px solid #E5E9F2;
                box-sizing: border-box;
                border-radius: 5px;
                line-height: 3;
                padding-left: .5em;
                width: 100%;
                &:focus {
                    outline-color: $color-primary;
                }
                &::placeholder {
                    color: $color-gray;
                }
            }
            .resend {
                color: $color-primary;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .form-bg {
        position: relative;
        width: 50%;
        height: 100%;
        @include respond(phone) {
            display: none;
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}