$color-primary: #6f972d;
$color-primary-light: #F2F9EB;
$color-secondary: #F69033; //using
$color-secondary-light: #f6701d; //using
$color-danger: #F63333; //using
$color-dark: #c4c4c4;
$color-dark-2: #313131;
$color-gray-light-1: #f2f2f2;
$color-gray-light-2: #f2f3f7;
$color-gray-light-3: #E5E9F2; //using
$color-gray-light-4: #FCFDFB; //using
$color-gray-light-5: #DFDEDE; //using
$color-gray-dark-1: #777;
$color-gray-dark-2: #999;
$color-gray-dark-3: #333;
$color-white: #fff;
$color-blue: #0052ce;
$color-black: #000; //using
$color-gold: #fab428;
$color-red: #f21b1b;
$color-social: #173460;
$default-font-size: 1.6rem;
$color-gray: #9DA8B6; //using
$color-gray-2: #DFDEDE; //using
// Grey Line below
$gray-line: 1px solid $color-gray-light-2;