// Font
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: 'inherit';
    font-family: Roboto;
}

body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    // background-color: $color-gray-light-2;
}

::selection {
    background-color: $color-primary;
    color: $color-white;
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

// line-height
.l-3 {
    line-height: 3 !important;
}

// buttons
.btn-primary {
    background-color: $color-primary !important;
    color: $color-white !important;
    border: none;
}

.btn-border-primary {
    border: 1px solid $color-primary !important;
    color: $color-primary !important;
    background: inherit;
    &:hover {
        background-color: $color-primary;
        color: $color-white !important;
    }
}

.btn-border-secondary {
    border: 1px solid $color-secondary !important;
    color: $color-secondary !important;
    background: inherit;
    &:hover {
        background-color: $color-secondary;
        color: $color-white !important;
    }
}

.btn-border-radius {
    border-radius: .2em;
}

.btn-gray {
    background-color: $color-gray !important;
    color: $color-gray-light-2 !important;
}

// text
.text-gray {
    color: $color-gray;
}

.text-secondary {
    color: $color-secondary;
}

.text-primary {
    color: $color-primary !important;
}

.text-danger {
    color: $color-danger !important;
}

a {
    text-decoration: none !important;
}

// background
.bg-primary-light {
    background-color: $color-primary-light;
}

// card
.card {
    border: none !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    margin-left: 1em;
}