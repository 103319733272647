.form-btn-action {
    position: relative;
    right: 0;
    a {
        button {
            width: 5em;
            margin-right: 1em;
        }
    }
}

.profile {
    background-color: $color-gray-light-4;
    .row {
        width: 100% !important;
    }
    padding-bottom: 2em;
}

// Personal Information
.personal-info {
    .form-container {
        label {
            color: $color-gray;
        }
        .input {
            background-color: inherit;
            border: 2px solid $color-gray-light-3;
            box-sizing: border-box;
            border-radius: 5px;
            line-height: 3;
            padding-left: .5em;
            width: 100%;
            margin-top: -.5em;
            &:focus {
                outline-color: $color-primary;
            }
            &::placeholder {
                color: $color-gray;
            }
        }
        .file-upload {
            p {
                color: $color-gray;
            }
            .file-box {
                border: 2px solid $color-gray-light-3;
                width: 12em;
                min-height: 2.5em;
                margin-right: 1em;
                img {
                    width: 100%;
                }
            }
            label {
                color: $color-secondary;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
            svg {
                margin-top: .5em;
                color: $color-secondary;
                margin-right: .5em;
            }
        }
    }
}

// Reset Password
.reset-password {
    .form-container {
        label {
            color: $color-gray;
        }
        .input {
            background-color: inherit;
            border: 2px solid $color-gray-light-3;
            box-sizing: border-box;
            border-radius: 5px;
            line-height: 3;
            padding-left: .5em;
            width: 100%;
            margin-top: -.5em;
            &:focus {
                outline-color: $color-primary;
            }
            &::placeholder {
                color: $color-gray;
            }
        }
        .must-container {
            .true {
                color: green;
                list-style-type: none;
                &:before {
                    content: '✓';
                    margin-left: -1em;
                }
            }
        }
    }
}

// Location Details
.location-details {
    .form-container {
        .input {
            background-color: inherit;
            border: 2px solid $color-gray-light-3;
            box-sizing: border-box;
            border-radius: 5px;
            line-height: 3;
            padding-left: .5em;
            width: 100%;
            margin-top: -.5em;
            &:focus {
                outline-color: $color-primary;
            }
        }
        select {
            background-color: inherit;
            border: 2px solid $color-gray-light-3;
            box-sizing: border-box;
            border-radius: 5px;
            padding-left: .5em;
            width: 100%;
            margin-top: -.5em;
            &:focus {
                outline-color: $color-primary;
            }
            &:hover {
                cursor: pointer;
            }
        }
        label {
            color: $color-gray;
        }
    }
    .form-btn-action {
        margin-right: 2em;
        button {
            margin: .5em .5em;
            &:nth-of-type(2) {
                width: 112px;
                height: 50px;
            }
        }
    }
}

// Payment Details
.payment-details {
    .form-container {
        .input {
            background-color: $color-gray-light-5;
            border: none;
            box-sizing: border-box;
            border-radius: 5px;
            line-height: 3;
            padding-left: .5em;
            width: 100%;
            margin-top: -.5em;
            opacity: 0.3;
            &:focus {
                outline-color: $color-primary;
            }
            &::placeholder {
                color: $color-black;
            }
        }
        // .form-check>input[type=radio] {
        //     .form-check-label::before {
        //         content: url(../../assets/icons/card.png);
        //         margin-right: .5em;
        //         margin-top: .5em;
        //     }
        // }
    }
}

// Subcription
.subscription {
    .form-check {
        margin-bottom: 1em;
        margin-right: 1em;
    }
    select {
        padding: .2em !important;
        margin-top: -1em;
        background-color: inherit;
        border: 2px solid $color-gray-light-3;
        box-sizing: border-box;
        border-radius: 5px;
        padding-left: .5em;
        @include respond(phone) {
            margin: 0 !important;
            font-size: .9em;
            padding: 1em;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .form-btn-action {
        button {
            margin: .5em .5em;
        }
    }
}